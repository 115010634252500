import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box } from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import AirportPagesNav from '../components/elements/AirportPagesNav'
import Book from '../components/elements/Book'

const AirportPage = ({ data }) => {
  const page = data.prismicAirportpage

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />

      <Hero>
        <BackgroundImage Tag="div" fluid={page.data.hero_image.fluid}>
          <Container
            py={['12em', '12em', '16em']}
            css={`
              z-index: 20;
              display: block;
              position: relative;
            `}
          />
        </BackgroundImage>
      </Hero>

      <Section>
        <Container>
          <Flex flexWrap="wrap" mx={-8}>
            <Box width={['full', 'full', 1 / 3]} px={8}>
              <AirportPagesNav />
            </Box>
            <Box width={['full', 'full', 2 / 3]} px={8}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Section>

      <Book />
    </Layout>
  )
}

export default AirportPage

export const query = graphql`
  query AirportPage {
    prismicAirportpage {
      data {
        meta_description
        meta_title
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_text {
          text
        }
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`
