import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Box, ListItem, UnorderedList } from '@chakra-ui/react'

function AirportPagesNav(props) {
  const data = useStaticQuery(graphql`
    query AirportPagesNav {
      allPrismicAirportPages {
        edges {
          node {
            data {
              title {
                text
              }
            }
            uid
            id
          }
        }
      }
    }
  `)

  return (
    <Box bg="primary" p={9} color="white">
      <Box as="h3">Airport Information</Box>
      <UnorderedList
        listStyleType="none"
        m={0}
        sx={{ a: { color: 'white', textDecoration: 'none' } }}
      >
        <ListItem>
          <Link to="/airport" activeClassName="active">
            Overview
          </Link>
        </ListItem>
        {data.allPrismicAirportPages.edges.map(({ node: item }) => (
          <ListItem key={item.id}>
            <Link to={`/airport/${item.uid}`} activeClassName="active">
              {item.data.title.text}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}

export default AirportPagesNav
