import React from 'react'
import { Box, Heading, Text, SimpleGrid } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import Iframe from 'react-iframe'

import { Section, Container } from '../layout'
import LinkExt from './LinkExt'

function Book(props) {
  return (
    <Section id="booking" bg="gray.900" sx={{ '*': { color: 'white' } }}>
      <Container>
        <SimpleGrid columns={[1, 1, 2]} spacing={9}>
          <Box>
            <Heading as="h2">Book a Flight</Heading>
            <Text fontSize={['lg', 'lg', 'xl']}>
              Reaching your destination is fast and easy. Book your flight with
              us today! For more destinations and interline tickets, call
              1-800-329-0485 or{' '}
              <LinkExt
                to="https://iflysouthern.com/"
                css={`
                  color: white;
                `}
              >
                visit Southern's website
              </LinkExt>
            </Text>

            <Box maxWidth={['60%']} mx="auto" mt={12}>
              <StaticImage
                src="../../images/southern_air_express_logo.png"
                alt="southern air express logo"
              />
            </Box>
          </Box>

          <Box p={6} bg="white">
            <Iframe
              url="
https://fo-latam.ttinteractive.com/Zenith/FrontOffice/SouthernAirwaysExpress/en-US?mode=iframe"
              width="100%"
              height="500px"
              id="Booking"
              display="initial"
              className="book-widget"
              position="relative"
              title="Book Tickets"
            />
          </Box>
        </SimpleGrid>
      </Container>
    </Section>
  )
}

export default Book
